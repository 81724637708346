import React, {useEffect, useMemo, useState} from 'react';
import {
    percentFromRatio,
    prettyInt,
    renderTitle,
    prettyFloat
} from "../../utils/summary";
import {timeout} from "../../../assets/javascripts/d3";

export default ({
                    level,
                    itemType,
                    initialData,
                    currentData,
                    project,
                    shownSections
                }) => {

    if (!initialData[level] || !currentData) return null;

    const iTotals = initialData[level].totals;
    const cTotals = currentData[level].totals;
    const iTags = initialData[level].tags;
    const cTags = currentData[level].tags;
    const [maximizedSections, setMaximizedSections] = useState({
        filtered: true,
        incoherent: true,
        incoherent_filtered: true
    });

    let currentGrandTotals = currentData['raw_input'].totals;
    let initialGrandTotals = initialData['raw_input'].totals;

    if (['incoherent_filtered'].includes(level)
    ) {
        currentGrandTotals = currentData['initial_filtered'].totals;
        initialGrandTotals = initialData['initial_filtered'].totals;
    }

    if (['selected'].includes(level)
    ) {
        currentGrandTotals = currentData['initial_filtered'].totals;
        initialGrandTotals = initialData['initial_filtered'].totals;
    }

    if (!project.filters) return null;

    const itemTypeTitle = ("" + itemType).charAt(0).toUpperCase() + itemType.slice(1);

    const showTableItems = (level) => {
        setMaximizedSections({
            ...maximizedSections,
            [level]: !maximizedSections[level]
        })
    }

    return (
        <React.Fragment>
            <tr className="totals">
                <th scope="row" className="label">
                    {level === 'incoherent_filtered' && (
                        <>
                            <a href={`/projects/${project.id}/issues/new?target=${itemType}`} className="with-icon add-link"
                               style={{float: 'left'}}>
                                <i className="fa-solid fa-add"></i>New Issue
                            </a>
                            <span
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    showTableItems('incoherent_filtered')
                                }}
                                className="incoherent_filtered with-icon show">
                                <i className={`fa-solid ${
                                    maximizedSections.incoherent_filtered ? 'fa-minus' : 'fa-plus'}`}/>
                                {`${itemTypeTitle} with issues and filters`}
                            </span>

                        </>
                    )}
                    {level === 'filtered' && (
                        <>
                            <a href={`/projects/${project.id}/filters/new?target=${itemType}`}
                               className="with-icon add-link"
                               style={{float: 'left'}}>
                                <i className="fa-solid fa-add"></i>New Filter
                            </a>
                            <span
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    showTableItems('filtered')
                                }}
                                className="filtered with-icon show"
                            >
                                <i className={`fa-solid ${
                                    maximizedSections.filtered ? 'fa-minus' : 'fa-plus'}`}/>
                                {`${itemTypeTitle} with filters`}
                        </span>
                        </>
                    )}
                    {level === 'incoherent' && (
                        <>
                            <a href={`/projects/${project.id}/issues/new?target=${itemType}`} className="with-icon add-link"
                               style={{float: 'left'}}>
                                <i className="fa-solid fa-add"></i>New Issue
                            </a>
                            <span
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    showTableItems('incoherent')
                                }}
                                className="incoherent with-icon show">
                                <i className={`fa-solid ${
                                    maximizedSections.incoherent ? 'fa-minus' : 'fa-plus'}`}/>
                                {`${itemTypeTitle} with issues`}
              </span>
                        </>
                    )}

                    {level === 'coherent' && `${itemTypeTitle} without issues`}
                    {level === 'initial_filtered' && `${itemTypeTitle} included in project`}
                    {level === 'selected' && `${itemTypeTitle} included in project without issues`}

                </th>
                {['all', 'act', 'abn'].map((status) => {
                    const _level = level.replace("incoherent_filtered", "incoherent") // needed to get the correct item type for the links
                    const _showProjectStats = shownSections.project_stats && _level === 'incoherent' ? '&excludeFilteredData=true' : '' // should only default for the incoherent level
                    let _life_status = ''
                    if (status === 'act') _life_status = '&life_status=active'
                    if (status === 'abn') _life_status = '&life_status=abandoned'

                    return shownSections[status] && (
                        <React.Fragment>
                            {shownSections.count && (
                                <React.Fragment>
                                    {shownSections.initial && (
                                        <td title={renderTitle(itemType, _level, 'number', true, status)}
                                            className={`count ${status} value left separator initial`}>
                                            <span className="initial">
                                                <a
                                                    target="_blank"
                                                    href={`/projects/${project.id}/${itemType}?scope=${_level}&initial=1${_showProjectStats}${_life_status}`}>{prettyInt(iTotals[status].count)}</a>
                                            </span>
                                        </td>
                                    )}
                                    {shownSections.current && (
                                        <td title={renderTitle(itemType, _level, 'number', false, status)}
                                            className={`count ${status} value current`}>
                                            <span className="current">
                                                <a target="_blank" href={`/projects/${project.id}/${itemType}?scope=${_level}${_showProjectStats}${_life_status}`}>{prettyInt(cTotals[status].count)}</a>
                                            </span>
                                        </td>
                                    )}
                                </React.Fragment>
                            )}
                            {shownSections.percent && (
                                <React.Fragment>
                                    {shownSections.initial && (
                                        <td title={renderTitle(itemType, _level, 'number_percent', true, status)}
                                            className={`count ${status} percent initial`}>
                                            <span
                                                className="initial">{percentFromRatio(iTotals[status].count, initialGrandTotals[status].count)}</span>
                                        </td>
                                    )}
                                    {shownSections.current && (
                                        <td title={renderTitle(itemType, _level, 'number_percent', false, status)}
                                            className={`count ${status} percent current`}>
                                            <span
                                                className="current">{percentFromRatio(cTotals[status].count, currentGrandTotals[status].count)}</span>
                                        </td>
                                    )}
                                </React.Fragment>
                            )}
                            {shownSections.length && (
                                <React.Fragment>
                                    {shownSections.initial && (
                                        <td title={renderTitle(itemType, _level, 'length', true, status)}
                                            className={`left separator length ${status} value initial`}>
                                            <span
                                                className="initial">{prettyFloat(iTotals[status].length / 5280.0)}</span>
                                        </td>
                                    )}
                                    {shownSections.current && (
                                        <td title={renderTitle(itemType, _level, 'length', false, status)}
                                            className={`length ${status} value current`}>
                                            <span
                                                className="current">{prettyFloat(cTotals[status].length / 5280.0)}</span>
                                        </td>
                                    )}
                                </React.Fragment>
                            )}
                            {shownSections.length && shownSections.percent && (
                                <React.Fragment>
                                    {shownSections.initial && (
                                        <td title={renderTitle(itemType, _level, 'length_percent', true, status)}
                                            className={`length ${status} percent initial`}>
                                            <span
                                                className="initial">{percentFromRatio(iTotals[status].length, initialGrandTotals[status].length)}</span>
                                        </td>
                                    )}
                                    {shownSections.current && (
                                        <td title={renderTitle(itemType, _level, 'length_percent', false, status)}
                                            className={`length ${status} percent current`}>
                                            <span
                                                className="current">{percentFromRatio(cTotals[status].length, currentGrandTotals[status].length)}</span>
                                        </td>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )
                })
                }
            </tr>

            {(level === 'incoherent' || level === 'filtered' || level === 'incoherent_filtered') && (() => {
                return <TableSection
                    level={level}
                    iTags={iTags}
                    cTags={cTags}
                    itemType={itemType}
                    initialGrandTotals={initialGrandTotals}
                    grandTotals={currentGrandTotals}
                    project={project}
                    shownSections={shownSections}
                    maximized={maximizedSections}
                />

            })()}
        </React.Fragment>
    );
}

const request = (verb, uri, body = {}, callback) => {
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector(
            'meta[name="csrf-token"]'
        ).content
    };

    const options = {
        method: verb,
        headers: headers
    };

    if (verb !== 'GET' && verb !== 'DELETE') {
        options.body = JSON.stringify(body);
    }

    fetch(uri, options)
        .then(response => {
            if (!response.ok) {
                return response.json().then(error => {
                    throw new Error(error.message || 'Something went wrong');
                });
            }
            return response.json();
        })
        .then(data => {
            if (callback) {
                callback(null, data);
            }
        })
        .catch(error => {
            if (callback) {
                callback(error);
            } else {
                console.error('Request failed:', error);
            }
        });
};

const TableSection = ({level, iTags, cTags, itemType, initialGrandTotals, grandTotals, project, shownSections, maximized}) => {
    const [hide, setHide] = useState(false);

    const sortedITags = Object.fromEntries(Object.entries(iTags).sort((a, b) => b[1].all.count - a[1].all.count));
    const sortedCTags = Object.fromEntries(Object.entries(cTags).sort((a, b) => b[1].all.count - a[1].all.count));

    // add the filters that have been created but not computed yet
    // these can be identified by the fact that they have no tag
    Object.entries(project.filters).forEach(([filterId, filter]) => {
        if (!filter.tag && filter.target === itemType) {
            filter.name = `(Created, needs recompiling) ${filter.name}`;
            sortedCTags[`${filterId}`] = {
                all: {count: 0, length: 0.0},
                act: {count: 0, length: 0.0},
                abn: {count: 0, length: 0.0}
            };
        }
    });

    useEffect(() => {
        let timer;
        if (!maximized[level]) {
            setHide(false);
            timer = setTimeout(() => {
                setHide(true);
            }, 500); // Match this to the fadeout animation duration
        } else {
            setHide(false);
        }

        return () => clearTimeout(timer);
    }, [maximized, level]);

    return (
        <React.Fragment>
            {Object.entries(sortedCTags).map(([statusTag, ct]) => {
                const it = sortedITags[statusTag] || {
                    act: {count: 0, length: 0.0},
                    abn: {count: 0, length: 0.0},
                    all: {count: 0, length: 0.0}
                };

                return (
                    <tr key={statusTag}
                        className={`sub-section ${level} ${hide ? 'hide' : ''} ${maximized[level] ? 'fadein' : 'fadeout'}`}>
                        <th scope="row" className="hoverable label">
                            {level === 'incoherent' && <div>
                                {/*
                                    | Uncomment this if you want to add delete issue functionality
                                    | next to the issue name in the table


                                <i style={{
                                    cursor: 'pointer',
                                    marginRight: '5px',
                                    color: 'red'
                                }}
                                   onClick={() => {
                                       if (project.issues[statusTag]?.builtin) return;
                                       window.confirm('Are you sure you want to delete this issue?') &&
                                       Api.delete(`projects/${project.id}/issue?issueId=${project.issues[statusTag]?.id}`).then((res) => {
                                           if (res.message === 'ok') window.location.reload();
                                       }).catch((error) => {
                                           console.error('Error:', error);
                                       })
                                   }}
                                   title={project.issues[statusTag]?.builtin ? 'Cannot delete built-in issue' : 'Delete issue'}
                                   className={`fa-solid ${project.issues[statusTag]?.builtin ? 'fa-ban' : 'fa-trash-alt'}`}
                                ></i>
                                */}
                                <a
                                    target="_blank"
                                    className={project.issues[statusTag]?.active ? '': 'disabled'} href={`/projects/${project.id}/issues/${project.issues[statusTag]?.id}`}>{project.issues[statusTag]?.name}</a>
                            </div>}
                            {level === 'filtered' && <div>
                                <i
                                    style={{
                                        cursor: 'pointer',
                                        marginRight: '5px',
                                        color: 'red'
                                    }}
                                    onClick={() => {
                                        window.confirm('Are you sure you want to delete this filter?') &&
                                        Api.delete(`projects/${project.id}/filter?filterId=${project.filters[statusTag]?.id}`).then((res) => {
                                            if (res.message === 'ok') window.location.reload();
                                        }).catch((error) => {
                                            console.error('Error:', error);
                                        })
                                    }}
                                    className='fa-solid fa-trash-alt'></i>
                                <a
                                    target="_blank"
                                    href={`/projects/${project.id}/filters/${project.filters[statusTag]?.id}`}>{project.filters[statusTag]?.name}</a>
                            </div>}
                            {level === 'incoherent_filtered' && <div>
                                <a
                                    target="_blank"
                                    className={project.issues[statusTag]?.active ? '': 'disabled'}
                                    href={`/projects/${project.id}/issues/${project.issues[statusTag]?.id}`}>{project.issues[statusTag]?.name}</a>
                            </div>}
                        </th>
                        {['all', 'act', 'abn'].map((status) => {
                            const _showProjectStats = shownSections.project_stats && level.includes('incoherent') ? '&excludeFilteredData=true' : '' // should only default for the incoherent level
                            let _status = ""
                            if (status === 'act') {
                                _status = "&life_status=active"
                            } else if (status === 'abn') {
                                _status = "&life_status=abandoned"
                            }
                            return shownSections[status] && (
                                    <React.Fragment key={status}>
                                        {shownSections.count && (
                                            <React.Fragment>
                                                {shownSections.initial && (
                                                    <td className={`count ${status} value left separator initial`}>
                                                    <span className="initial">
                                                        {
                                                            project.filters[statusTag] || project.issues[statusTag]?.active ?
                                                                <a
                                                                    target="_blank"
                                                                    href={`/projects/${project.id}/${itemType}?scope=${statusTag}&initial=1${_showProjectStats}${_status}`}>
                                                                    {prettyInt(it[status].count)}
                                                                </a>
                                                                :
                                                                <s>
                                                                    <a
                                                                        target="_blank"
                                                                        href={`/projects/${project.id}/${itemType}?scope=${statusTag}&initial=1${_showProjectStats}${_status}`}>
                                                                        {prettyInt(it[status].count)}
                                                                    </a>
                                                                </s>
                                                        }
                                                    </span>
                                                    </td>
                                                )}
                                                {shownSections.current && (
                                                    <td className={`count ${status} value current`}>
                                                    <span className="current">
                                                        <a target="_blank"
                                                           href={`/projects/${project.id}/${itemType}?scope=${statusTag}${_showProjectStats}${_status}`}>
                                                            {prettyInt(ct[status].count)}
                                                        </a>
                                                    </span>
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {shownSections.percent && (
                                            <React.Fragment>
                                                {shownSections.initial && (
                                                    <td className={`count ${status} percent initial`}>
                                                    <span className="initial">
                                                        {project.filters[statusTag] || project.issues[statusTag]?.active ?
                                                            percentFromRatio(it[status].count, initialGrandTotals[status].count) :
                                                            <s>{percentFromRatio(it[status].count, initialGrandTotals[status].count)}</s>
                                                        }
                                                    </span>
                                                    </td>
                                                )}
                                                {shownSections.current && (
                                                    <td className={`count ${status} percent current`}>
                                                    <span className="current">
                                                        {percentFromRatio(ct[status].count, grandTotals[status].count)}
                                                    </span>
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {shownSections.length && (
                                            <React.Fragment>
                                                {shownSections.initial && (
                                                    <td className={`left separator length ${status} value initial`}>
                                                    <span className="initial">
                                                        {
                                                            project.filters[statusTag] || project.issues[statusTag]?.active ?
                                                                prettyFloat(it[status].length / 5280.0)
                                                                :
                                                                <s>{prettyFloat(it[status].length / 5280.0)}</s>
                                                        }
                                                    </span>
                                                    </td>
                                                )}
                                                {shownSections.current && (
                                                    <td className={`length ${status} value current`}>
                                                    <span className="current">
                                                        {prettyFloat(ct[status].length / 5280.0)}
                                                    </span>
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {shownSections.length && shownSections.percent && (
                                            <React.Fragment>
                                                {shownSections.initial && (
                                                    <td className={`length ${status} percent initial`}>
                                                    <span className="initial">
                                                        {project.filters[statusTag] || project.issues[statusTag]?.active ?
                                                            percentFromRatio(it[status].length, initialGrandTotals[status].length)
                                                            :
                                                            <s>{percentFromRatio(it[status].length, initialGrandTotals[status].length)}</s>
                                                        }
                                                    </span>
                                                    </td>
                                                )}
                                                {shownSections.current && (
                                                    <td className={`length ${status} percent current`}>
                                                    <span className="current">
                                                        {percentFromRatio(ct[status].length, grandTotals[status].length)}
                                                    </span>
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )
                            }
                        )}
                    </tr>
                );
            })}
            <style jsx>{`
                .fadein {
                    animation: fadein 0.5s ease-out 0s 1 normal forwards;
                    display: table-row;
                    visibility: visible;
                }
                @keyframes fadein {
                    from { opacity: 0; transform: translateY(-10px); }
                    to   { opacity: 1; transform: translateY(0); }
                }
                .fadeout {
                    animation: fadeout 0.5s ease-out 0s 1 normal forwards;
                    display: table-row;
                }
                @keyframes fadeout {
                    from { opacity: 1; transform: translateY(0px); }
                    to   { opacity: 0; transform: translateY(10px); }
                }
                .hide {
                    display: none;
                }
            `}</style>
        </React.Fragment>
    );
};